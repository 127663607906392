<template>
  <div class="view h-100">
    <div class="strip-bg strip-bg--condensed strip-bg--blue">
      <div class="container pos-r valign-center" style="min-height: 7rem;">
        <h2 class="font--biggest h-100 m-0 color-white">
          Arbeitsaufwand
        </h2>
      </div>
    </div>

    <div class="container pos-r">
      <el-row :gutter="20" style="top: -2.5rem;" class="m-l-0 m-r-0">
        <el-col :xs="24" :md="12" :lg="6" class="m-b-1">
          <el-card>
            <CardKPI :value="todayWorkHours" :percentage="todayWorkPercentage" />
          </el-card>
        </el-col>

        <el-col :xs="24" :md="12" :lg="6" class="m-b-1">
          <el-card>
            <CardKPI :value="weekWorkHours" :percentage="weekWorkPercentage">
              <template v-slot:header>Woche</template>
            </CardKPI>
          </el-card>
        </el-col>

        <el-col :xs="24" :md="12" :lg="6" class="m-b-1--sm">
          <el-card>
            <CardKPI :value="monthWorkHours" :percentage="monthWorkPercentage">
              <template v-slot:header>{{ format(new Date(), 'MMMM') }}</template>
            </CardKPI>
          </el-card>
        </el-col>

        <el-col :xs="24" :md="12" :lg="6">
          <el-card>
            <CardKPI :value="yearWorkHours" :percentage="yearWorkPercentage">
              <template v-slot:header>{{ format(new Date(), 'yyyy') }}</template>
            </CardKPI>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div class="container m-t-2 p-b-half p-1--sm m-b-1">
      <!-- Chart -->
      <el-row :gutter="20" class="m-l-0 m-r-0">
        <el-col :xs="24" :sm="16">
          <!-- Worktime -->
          <h3 class="font--regular m-t-0">
            <span>Arbeitsstundenstatistik</span>
          </h3>

          <el-card>
            <ChartWorkload v-if="currentUserId" />
          </el-card>
        </el-col>

        <!-- Project time -->
        <el-col :xs="24" :sm="8" class="m-t-1--sm">
          <h3 class="font--regular m-t-0">
            <span>Projekt-Zeit-Statistik</span>
          </h3>

          <el-card>
            <ChartCustomerWorkload v-if="currentUserId" />
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { format } from 'date-fns';
import CardKPI from '@/components/Card/CardKPI';
import ChartCustomerWorkload from '@/components/Chart/ChartCustomerWorkload';
import ChartWorkload from '@/components/Chart/ChartWorkload';
import FormTimetracking from '@/components/Form/FormTimetracking';
import FormTravelTracking from '@/components/Form/FormTravelTracking';

export default {
  name: 'TimesIndex',

  components: {
    CardKPI,
    ChartCustomerWorkload,
    ChartWorkload,
    FormTimetracking,
    FormTravelTracking
  },

  computed: {
    ...mapGetters({
      auth: 'auth/auth',
      currentMonthWorkloadTargetFigures: 'targetFigures/currentMonthWorkloadTargetFigures',
      currentYearWorkloadTargetFigures: 'targetFigures/currentYearWorkloadTargetFigures',
      monthWorkHours: 'tracking/monthWorkHours',
      monthWorkTracking: 'tracking/monthWorkTracking',
      tracking: 'tracking/all',
      todayWorkHours: 'tracking/todayWorkHours',
      todayWorkPercentage: 'tracking/todayWorkPercentage',
      todayWorkTracking: 'tracking/todayWorkTracking',
      user: 'user/currentUser',
      weekWorkHours: 'tracking/weekWorkHours',
      weekWorkPercentage: 'tracking/weekWorkPercentage',
      weekWorkTracking: 'tracking/weekWorkTracking',
      yearWorkHours: 'tracking/yearWorkHours'
    }),

    currentUserId() {
      return get(this.user, 'id');
    },

    monthWorkPercentage() {
      return Math.round((this.monthWorkHours * 100) / this.currentMonthWorkloadTargetFigures);
    },

    yearWorkPercentage() {
      return Math.round((this.yearWorkHours * 100) / this.currentYearWorkloadTargetFigures);
    }
  },

  data() {
    return {
      cards: [
        {
          title: 'Heute',
          value: 0,
          percentage: 0
        },
        {
          title: 'Woche',
          value: 0,
          percentage: 0
        },
        {
          title: 'Mai',
          value: 0,
          percentage: 0
        },
        {
          title: '2019',
          value: 0,
          percentage: 0
        }
      ]
    };
  },

  methods: {
    format
  }
};
</script>
